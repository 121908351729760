<template>
  <div>
    <page-header icon="fas fa-sliders" :title="title" :links="getLinks()"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div ref="printMe" v-if="!isLoading(loadingName)">
      <div class="templates-list small-text" ref="excelMe">
        <x-table
          :columns="columns"
          :items="items"
          :show-counter="true"
          verbose-name="modèle"
        ></x-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import XTable from '@/components/Controls/Table/Table.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi, openDocument } from '@/utils/http'
import { makeCafTemplateModel } from '@/types/reports'
import { makeYouthHome } from '@/types/youth'

export default {
  name: 'SeanceTemplateModelsView',
  mixins: [BackendMixin],
  components: {
    PageHeader,
    LoadingGif,
    XTable,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'SeanceTemplateModelsView',
      templates: [],
      columns: [],
      selection: [],
      youthHomesMap: new Map(),
    }
  },
  async mounted() {
    this.columns = this.getColumns()
    await this.loadYouthHomes()
    await this.loadData()
  },
  computed: {
    title() {
      return 'Modèles CAF'
    },
    items() {
      return this.templates.map(
        elt => this.makeItem(elt)
      )
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    getColumns() {
      return [
        {
          name: 'kind',
          label: 'Déclaration CAF',
        },
        {
          name: 'youthHomeName',
          label: 'Accueil de loisirs',
        },
        {
          name: 'seanceType',
          label: 'Type',
        },
        {
          name: 'template',
          label: 'Intitulé',
        },
        {
          name: 'convention',
          label: 'N° convention',
        },
        {
          name: 'declaration',
          label: 'Déclaration',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'configuration-caf'
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'configuration-caf'
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      const isDisabled = this.isLoading(this.loadingName) // && !this.selection.length
      return [
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'far fa-file-excel',
          cssClass: isDisabled ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'far fa-file-pdf',
          cssClass: isDisabled ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    makeItem(elt) {
      let youthHomeName = ''
      if (this.youthHomesMap.has(elt.seanceTemplate.youthHomeNumber)) {
        youthHomeName = this.youthHomesMap.get(elt.seanceTemplate.youthHomeNumber)
      } else {
        youthHomeName = '' + elt.seanceTemplate.youthHomeNumber
      }
      return {
        id: elt.id,
        youthHomeName: youthHomeName,
        kind: elt.model.modelTypeName,
        name: elt.model.getLabel(),
        convention: elt.model.convention.number,
        template: elt.seanceTemplate.name,
        seanceType: elt.seanceTemplate.seanceType.name,
        declaration: elt.model.declaration,
      }
    },
    async loadYouthHomes() {
      let url = '/api/youth/active-youth-homes/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        const youthHomes = resp.data.map(elt => makeYouthHome(elt))
        const youthHomesMap = new Map()
        for (const youthHome of youthHomes) {
          youthHomesMap.set(youthHome.number, youthHome.name)
        }
        this.youthHomesMap = youthHomesMap
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadData() {
      let url = '/reports/api/template-caf-models/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.templates = resp.data.map(makeCafTemplateModel)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped>
</style>
